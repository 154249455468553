<template>
  <header-base :sear="'hide'"></header-base>
  <div class="content">
    <div class="detail_title">
      <p class="detail_title_p">
        订单状态：
        <span class="title_p_span">{{
                list.status == 0
                ? "待活动开始"
                : list.status == 1
                ? "活动中"
                : list.status == 2
                ? "未成交"
                : '已成交'}}</span>
      </p>
    </div>
    <div class="detail_content">
      <div class="left">
        <div class="rowdiv">
          <div class="row_left">
            <span class="msg_title">支付时间：</span>
            <span class="msg_cont">{{ list.createTime }}</span>
          </div>
          <div class="row_left">
            <span class="msg_title">支付金额：</span>
            <span class="msg_cont">{{ list.price }}</span>
          </div>
          <div class="row_left">
            <span class="msg_title">购买数量：</span>
            <span class="msg_cont">{{ list.num }}{{ list.smsAuctionProduct.unit }}</span>
          </div>
          <div class="row_left" v-if="list.status != 2">
            <span class="msg_title">订单编号：</span>
            <span class="msg_cont">{{list.orderSn }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <h3>订单信息</h3>
        <div class="r_cont">
          <img :src="list.smsAuctionProduct.productPic ">
          <p>{{list.smsAuctionProduct.productName}}</p>
        </div>
        <div class="r_foot">
          <span>{{
                list.status == 0
                ? "出价中"
                : list.status == 1
                ? "已成交"
                : "未成交"}}</span>
          <p><i>结束时间：</i>{{list.smsAuctionProduct.endTime}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="divfooter">
      <Footer/>
  </div>
</template>

<script>
import HeaderBase from '../../../components/HeaderBase.vue'
import { getStore } from '../../../tools/storage'
import Footer from '../../index/Footer.vue'
export default {
  components: { HeaderBase, Footer },
  name: 'MyAuctionDetails',
  data () {
    return {
      list: ''
    }
  },
  methods: {

  },
  created () {
    window.document.documentElement.scrollTop = 0
    this.list = JSON.parse(getStore('AuctionMsg'))
    console.log(this.list)
  },
  setup () {
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>
@import '../../../style/viriables.scss';
.content{
  background: #f2efed;
  // margin-top: 10px;
  // height: 870px;
  padding: 10px 0 40px;
  .detail_title{
    width: 1200px;
    margin: 0 auto;
    height: 70px;
    padding: 0 20px;
    background: $divbgColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail_title_p{
      font-size: 14px;
      color: #333333;
      .title_p_span{
        font-size: 18px;
        color: #C6824E;
        font-weight: bold;
      }
    }
  }
  .detail_content{
    height: 800px;
    width: 1200px;
    margin: 0 auto;
    background: $divbgColor;
    padding: 0 20px;
    display: flex;
    .left{
      flex: 1;
      padding: 50px;
      box-sizing: border-box;
      background: #FFEEE0;
      border: 1px solid #C6824E;
      height: 317px;
      .rowdiv{
        display: flex;
        flex-flow: column;
        line-height: 50px;
        .row_left{
          flex: 1;
          .msg_title{
            display: inline-block;
            color: #999999;
            width: 100px;
            max-width: 100px;
            text-align-last: justify;
          }
          .msg_cont{
            color: #333333;
            .more{
              color: #C6824E;
              cursor: pointer;
            }
          }
        }
      }
    }
    .right{
      height: 317px;
      background: #F7F7F7;
      flex: 1;
      display: flex;
      flex-flow: column;
      // padding: 0 2px;
      h3{
        font-weight: bold;
        margin: 19px 23px;
        font-size: 18px;
      }
      .r_cont{
        padding: 0 23px;
        display: block;
        flex: 1;
        img{
          float: left;
          width: 145px;
          height: 145px;
        }
        p{
          display: inline-block;
          margin-left: 40px;
        }
      }
      .r_foot{
        display: flex;
        height: 66px;
        line-height: 66px;
        text-align: center;
        span{
          font-weight: bold;
          font-size: 18px;
          display: block;
          color: #ffffff;
          height: 100%;
          width: 177px;
          background: #C6824E;
        }
        p{
          flex: 1;
          i{
            color: #999999;
          }
          // display: inline-block;
        }
      }
    }
  }
}
.divfooter{
  background: #280E01;
}
</style>
